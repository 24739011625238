import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  HttpClient
} from '@angular/common/http';
import {
  BehaviorSubject,
  Subject,
  Observable,
  ReplaySubject
} from 'rxjs';
import {
  ToastrService
} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  public getCategory = new Subject < string > ();
  public userData = new ReplaySubject < Object > ();
  public open = new ReplaySubject < object > ();
  public cartListCount = new ReplaySubject < Object > ();
  public sideBarId = new ReplaySubject < object > ();
  public className = new ReplaySubject < object > ();
  public isAuthenticated = new ReplaySubject < object > ();
  public isLoggedOut = new ReplaySubject < object > ();
  private search_value: any;
  // public productCount: any

  // setCount(value) {
  //    this.productCount = value;
  // }

  // getCount() {
  //     return this.productCount;
  // }
  constructor(private router: Router, private toastr: ToastrService) {}

  setGetSearchValue(data = null) {
    if (data) {
      this.search_value = data;
    } else {
      return this.search_value;
    }
  }

  setUserData(value) {
    this.userData.next(value);
  }

  getUserData() {
    return this.userData.asObservable();
  }

  setIsLogin(value) {
    this.isAuthenticated.next(value)
  }

  getIsLogin() {
    return this.isAuthenticated.asObservable();
  }

  setLogOut(value) {
    this.isLoggedOut.next(value)
  }

  getIsLogOut() {
    return this.isLoggedOut.asObservable();
  }

  openLoginPopUp() {
    this.open.next({});
  }

  openPopUp() {
    return this.open.asObservable();
  }


  broadcastProductCategory(type: string) {
    this.getCategory.next(type);
  }

  getProductCategory(): Observable < any > {
    return this.getCategory.asObservable();
  }

  setCartListCount(value) {
    this.cartListCount.next(value)
  }

  getCartListCount() {
    return this.cartListCount.asObservable();
  }

  setClassName(id, className) {
    this.className.next(className)
    this.sideBarId.next(id)
  }

  getClassName() {
    return this.className.asObservable();
  }

  unAuthorizedUserAccess(error, flag) {
    if (error.status == 401) {
      localStorage.removeItem('userData')
      localStorage.removeItem('token')

      this.setUserData(null);

      if (flag == 'front') {
        this.router.navigate(['']);
      } else {
        this.router.navigate(['/admin/login']);
      }
    } else {
      // this.toastr.error(error.message)
    }
  }
}