import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {Location } from '@angular/common';
import { DataService } from '../modules/user/data.service';

@Injectable()

export class LoginGuard implements CanActivate {

  constructor(
    private router:Router,
    private location:Location,
    private toastr:ToastrService,
    private AppService : DataService
  ){}
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      var userData = JSON.parse(localStorage.getItem('userData'));
      var token = JSON.parse(localStorage.getItem('token'));

      if(userData && token){
        this.AppService.setIsLogin(true);
        return true
      }else{
        this.AppService.setIsLogin(false);     
        return true
      }
  }
}
