// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



var baseUrl
var imageUrl
var userUrl
baseUrl = 'https://admin.ztecpc.com/api9oa2XQiNGI/'
userUrl = 'https://admin.ztecpc.com/user9oa2XQiNGI/'
imageUrl='https://admin.ztecpc.com/public/uploads/images/product_images/'
export const environment = {
  production: false,
  url:baseUrl,
  userUrl:userUrl,
  imageUrl:imageUrl,
  userData : (localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : null
};  
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
