import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HttpClientModule } from '@angular/common/http';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { UserGuard } from './guards/user.guard';
import { LoginGuard } from './guards/login.guard';
import { NgxBraintreeModule } from 'ngx-braintree';

import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CommonModule,
    TransferHttpCacheModule,
    HttpClientModule,
    NgtUniversalModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({timeOut: 20000,
    extendedTimeOut: 1000,
    closeButton: true,maxOpened: 1, newestOnTop: true, autoDismiss: true, progressBar: true}),
    NgxBraintreeModule
  ],
  providers: [UserGuard, LoginGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
