import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {Location } from '@angular/common';
import { DataService } from '../modules/user/data.service';
declare var $: any

@Injectable()

export class UserGuard implements CanActivate {

  constructor(
    private router:Router,
    private location:Location,
    private toastr:ToastrService,
    private AppService : DataService
  ){}
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      var userData = JSON.parse(localStorage.getItem('userData'));
      var token = JSON.parse(localStorage.getItem('token'));

      if(userData && token){
        var role_id=userData.role_id
        if(role_id!=1){
          return true
        }else{
          
          this.location.back()
        }
      }else{
        this.AppService.setUserData(null);
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        // this.toastr.warning('Please login First.')
        this.router.navigate([''])
        $('#user_login').modal('show');      
        return false
      }
    return true;
  }
}
